// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../utils/assets/9.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-box {\n  width: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-position: center center;\n  background-size: cover;\n  position: fixed;\n  overflow: auto;\n  top: 0px;\n  bottom: 0px;\n}\n\n.login {\n  background: white;\n  padding-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/login/login.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yDAAiD;EACjD,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kCAAkC;EAClC,sBAAsB;EACtB,eAAe;EACf,cAAc;EACd,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".login-box {\n  width: 100%;\n  background-image: url(\"../../utils/assets/9.jpg\");\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-position: center center;\n  background-size: cover;\n  position: fixed;\n  overflow: auto;\n  top: 0px;\n  bottom: 0px;\n}\n\n.login {\n  background: white;\n  padding-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
